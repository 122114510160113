import { authGuard } from './domains/auth/auth.guard';
import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';

export const appRoutes: VexRoutes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/auth/login/login.component').then(
        (m) => m.LoginComponent
      )
  }, {
    path: 'autologin',
    loadComponent: () =>
      import('./pages/auth/autologin//autologin.component').then(
        (m) => m.AutologinComponent
      )

  }, {
    path: 'register',
    loadComponent: () =>
      import('./pages/auth/register/register.component').then(
        (m) => m.RegisterComponent
      )
  }, {
    path: 'forgot-password',
    loadComponent: () =>
      import(
        './pages/auth/forgot-password/forgot-password.component'
      ).then((m) => m.ForgotPasswordComponent)
  }, {
    path: 'reset-password',
    loadComponent: () =>
      import(
        './pages/auth/reset-password/reset-password.component'
      ).then((m) => m.ResetPasswordComponent)
  }, {
    path: 'coming-soon',
    loadComponent: () =>
      import('./pages/coming-soon/coming-soon.component').then(
        (m) => m.ComingSoonComponent
      )
  }, {
    path: 'oauth-result',
    loadComponent: () =>
      import('./pages/oauth-result/oauth-result.component').then(
        (m) => m.OauthResultComponent
      )
  }, {
    path: '',
    component: LayoutComponent,
    canActivateChild: [authGuard],
    children: [{
      path: 'inbox',
      loadComponent: () =>
        import(
          './pages/inbox/inbox.component'
        ).then((m) => m.InboxComponent)
    }, {
      path: 'home',
      loadComponent: () =>
        import(
          './pages/home/home.component'
        ).then((m) => m.HomeComponent)
    }, {
      path: 'integrations',
      loadComponent: () =>
        import(
          './pages/integrations/integrations.component'
        ).then((m) => m.IntegrationsComponent),
      children: [
        {
          path: '',
          redirectTo: 'all',
          pathMatch: 'full',
        }, {
          path: 'all',
          loadComponent: () =>
            import('./pages/integrations/integrations-main/integrations-main.component').then(m => m.IntegrationsMainComponent),
        }, {
          path: 'api-keys',
          loadComponent: () =>
            import('./pages/integrations/integrations-apikeys/integrations-apikeys.component').then(m => m.IntegrationsApikeysComponent),
        }, {
          path: '**',
          redirectTo: 'all'
        }
      ]
    }, {
      path: 'subscription',
      loadComponent: () =>
        import(
          './pages/subscription/subscription.component'
        ).then((m) => m.SubscriptionComponent)
    }, {
      path: 'settings',
      loadComponent: () =>
        import(
          './pages/settings/settings.component'
        ).then((m) => m.SettingsComponent),
      children: [
        {
          path: '',
          redirectTo: 'general',
          pathMatch: 'full',
        }, {
          path: 'general',
          loadComponent: () =>
            import('./pages/settings/general-settings/general-settings.component').then(m => m.GeneralSettingsComponent),
        }, {
          path: 'billing',
          loadComponent: () =>
            import('./pages/settings/billing-settings/billing-settings.component').then(m => m.BillingSettingsComponent),
        }, {
          path: '**',
          redirectTo: 'general'
        }
      ]
    }, {
      path: 'preferences',
      loadComponent: () =>
        import(
          './pages/preferences/preferences.component'
        ).then((m) => m.PreferencesComponent)
    }, {
      path: '**',
      redirectTo: 'home'
    }]
  }];
